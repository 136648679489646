body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.nav {
  position: absolute;
  z-index: 100;
}

.nav-item {
  font-size: 18px;
}

.container__inner {
  position: relative;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: calc(100% - 170px);
}

video {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1;
  min-width: 100%;
  height: 101%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transition: opacity .5s ease;
  -o-transition: opacity .5s ease;
  transition: opacity .5s ease;
}